import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <article
        className="static-post"
        style={{ padding: `0 var(--spacing-4) var(--spacing-10)` }}
      >
        <h3>お問い合わせ</h3>
        <p>「{siteTitle}」はリンクフリーです。</p>
        <p>ご意見・ご要望などありましたら下記よりご連絡ください。</p>
        <ul>
          <li>
            稀にメールが届かない場合があります。返事がない場合は、お手数ですが再度ご連絡ください。
          </li>
          <li>
            レイアウト等の表示に問題がある方は、ご使用中のブラウザと該当箇所をご記入の上ご連絡頂ければ幸いです。
          </li>
        </ul>
        <a
          href="https://form.run/@389999"
          target="_blank"
          rel="noopener noreferrer"
        >
          お問い合わせはこちらから
        </a>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Contact

export const Head = () => (
  <Seo title="お問い合わせ" description="お問い合わせ" />
)
